<template>
    <div class="gift-card-list break-auto">
        <v-row :class="$vuetify.display.mdAndDown ? 'ma-n4px ma-md-n6px' : ''">
            <v-col v-for="brand in brands" :key="brand._id" cols="4" sm="3" md="2" :class="$vuetify.display.mdAndDown ? 'pa-4px pa-md-6px' : ''">
                <v-card variant="outlined" rounded="circle" @click="navigate(brand)">
                    <v-responsive :aspect-ratio="1 / 1">
                        <v-img :src="brand.thumb.src" />
                    </v-responsive>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from "@/api";
export default {
    props: {
        filter: { type: Object, default: {} }
    },
    components: {},
    data() {
        return {
            brands: []
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(){
            let { brands } = await api.v1.gshop.brands.gets({ params: { ...this.$props.filter }});
            this.brands = brands;
        },
        navigate(brand){
            if(brand.giftcards.length){
                this.$router.push(`/sub/purchase/detail/${brand.giftcards[0]._id}`);
            }
            else alert("등록된 상품이 없습니다");
        }
    },
    watch: {
        filter: {
            deep: true,
            handler(){
                this?.search?.();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.v-card {
    &:hover,
    &--active {
        box-shadow: var(--shadow-elevation-10) rgba(var(--shadow-color), var(--shadow-opacity));
    }
    :deep() {
        .v-card__overlay {
            opacity: 0 !important;
        }
    }
}
</style>
