<template>
    <app-primary>
        <page-section-primary titWrapAlign="start" class="page-section--first page-section--last">
            <template #tit>상품권 구매하기</template>

            <gift-card-recommend />
        </page-section-primary>

        <section>
            <v-container>
                <v-row align="center">
                    <v-col>
                        <gift-card-category v-model="filter._category" />
                    </v-col>
                    <v-col cols="12" md="auto">
                        <gift-card-search v-model="filter.searchValue" />
                    </v-col>
                </v-row>
            </v-container>
            <v-divider class="gift-card-divider" />
        </section>

        <page-section-primary titWrapAlign="start" class="pt-60px page-section--last">
            <!-- <template #tit>어떤 상품을 찾고 계신가요?</template> -->
            <gift-card-List :filter="filter" />
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import PageSectionSecondary from "@/sets/parents/pages/page-section-secondary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

import GiftCardCategory from "@/components/publish/styles/gift-cards/gift-card-category.vue";
import GiftCardSearch from "@/components/publish/styles/gift-cards/gift-card-search.vue";
import GiftCardList from "@/components/publish/styles/gift-cards/gift-card-list.vue";
import GiftCardBrand from "@/components/publish/styles/gift-cards/gift-card-brand.vue";
import GiftCardRecommend from "@/components/publish/styles/gift-cards/gift-card-recommend.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        PageSectionSecondary,
        BtnPrimary,

        GiftCardCategory,
        GiftCardSearch,
        GiftCardList,
        GiftCardBrand,
        GiftCardRecommend,
    },
    data() {
        return {
            filter: {
                _category: null,
                searchKey: "name",
                searchValue: null,
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.gift-card-divider {
    position: relative;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .gift-card-divider {
        margin-top: 0;
    }
}
</style>
