<template>
    <component v-if="order" ref="pg" :is="order.paymentGateway" :order="order"/>
</template>

<script>
import korpay from "@/components/plugins/korpay/korpay.vue";

export default {
    components: {
        korpay
    },
    data(){
        return { order: null };
    },
    methods: {
        pay(){
            this.$refs?.pg?.pay?.();
        }
    }
}
</script>