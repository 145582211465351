<template>
    <swiper-container v-bind="swiperOptions" class="gift-card-slide">
        <swiper-slide v-for="giftcard in giftcards" :key="giftcard._id">
            <v-chip v-if="giftcard.isBadge" color="primary" prepend-icon="mdi-sale" :text="giftcard.discount ? `최대 ${giftcard.discount}% 할인`: `할인`" class="position-absolute" />
            <v-card variant="outlined" rounded="circle" :to="`/sub/purchase/detail/${giftcard._id}`">
                <v-responsive :aspect-ratio="1 / 1">
                    <v-img :src="giftcard.thumb.src" />
                </v-responsive>
            </v-card>
        </swiper-slide>
    </swiper-container>
</template>

<script>
import api from "@/api";
export default {
    props: {
        filter: { type: Object, default: () => {} },
    },
    data() {
        return {
            giftcards: [],

            /** @type {import("swiper/types/swiper-options").SwiperOptions} */
            swiperOptions: {
                slidesPerView: 2,
                spaceBetween: 8,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                // navigation: {
                //     prevEl: ".swiper-button-prev",
                //     nextEl: ".swiper-button-next",
                // },
                breakpoints: {
                    576: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 6,
                        spaceBetween: 12,
                    },
                },
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            let { giftcards } = await api.v1.gshop2.giftcards.gets({ params: { ...this.$props.filter, type: "giftcard", isRecommend: true } });
            this.giftcards = giftcards;
        },
    },
    watch: {
        filter: {
            deep: true,
            handler() {
                this?.search?.();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.gift-card-slide {
    .swiper-control {
        top: 50%;
        transform: translate(-50%, -50%);
        .swiper-button-prev,
        .swiper-button-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            :deep() {
                .v-icon {
                    font-size: var(--btn-icon-font-size-xxl) !important;
                }
            }
        }
        .swiper-button-prev {
            left: 0;
        }
        .swiper-button-next {
            right: 0;
        }
    }
}
:deep() {
    .gift-card-slide {
        ::slotted(.swiper) {
            overflow: visible;
        }
    }
}
.v-card {
    &:hover,
    &--active {
        box-shadow: 0 0 8px 0 rgba(var(--shadow-color), var(--shadow-opacity));
    }
    :deep() {
        .v-card__overlay {
            opacity: 0 !important;
        }
    }
}
</style>
