var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gift-card-list break-auto"
  }, [_vm.giftcards.length > 0 ? [_c('v-row', {
    class: _vm.$vuetify.breakpoint.mdAndDown ? 'ma-n4px ma-md-n6px' : ''
  }, _vm._l(_vm.giftcards, function (giftcard) {
    var _giftcard$thumb;
    return _c('v-col', {
      key: giftcard._id,
      class: _vm.$vuetify.breakpoint.mdAndDown ? 'pa-4px pa-md-6px' : '',
      attrs: {
        "cols": "4",
        "sm": "3",
        "md": "2"
      }
    }, [_c('v-card', {
      staticClass: "gift-card-list__item",
      attrs: {
        "to": `/gshop/giftcards/${giftcard._id}`
      }
    }, [_c('v-card', {
      staticClass: "thumb-image",
      attrs: {
        "img": (_giftcard$thumb = giftcard.thumb) === null || _giftcard$thumb === void 0 ? void 0 : _giftcard$thumb.src,
        "variant": "outlined",
        "rounded": ""
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    })], 1), _c('v-card-subtitle', {
      staticClass: "text-center pb-2"
    }, [_c('txt', {
      staticClass: "txt--dark font-weight-bold"
    }, [_vm._v(" " + _vm._s(giftcard.name) + " ")])], 1), _c('v-card-title', {
      staticClass: "py-0 justify-center primary--text"
    }, [_c('tit', {
      staticClass: "tit--xxs"
    }, [_vm._v(_vm._s(giftcard.salePrice.toLocaleString()) + "원")])], 1)], 1)], 1);
  }), 1)] : [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    staticClass: "font-size-120",
    attrs: {
      "color": "grey lighten-2"
    }
  }, [_vm._v("mdi-alert-circle-outline")]), _c('tit', {
    staticClass: "grey--text text--lighten-2 mt-5"
  }, [_vm._v("상품 준비 중입니다")])], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }