<template>
    <text-field-primary append-icon="mdi-magnify" outlined placeholder="상품 검색" hide-details @input="update" />
</template>

<script>
import textFieldPrimary from "../../parents/forms/text-field-primary.vue";
export default {
    components: {
        textFieldPrimary,
    },
    props: {
        value: String,
    },
    methods: {
        update(value) {
            this.$emit(`input`, value);
        },
    },
};
</script>