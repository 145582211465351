var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('text-field-primary', {
    attrs: {
      "append-icon": "mdi-magnify",
      "outlined": "",
      "placeholder": "상품 검색",
      "hide-details": ""
    },
    on: {
      "input": _vm.update
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }