var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', [_c('v-tab', {
    on: {
      "click": function ($event) {
        return _vm.$emit('input', null);
      }
    }
  }, [_vm._v("전체상품")]), _vm._l(_vm.categories, function (category) {
    return _c('v-tab', {
      key: category._id,
      on: {
        "click": function ($event) {
          return _vm.$emit(`input`, category._id);
        }
      }
    }, [_vm._v(" " + _vm._s(category.name) + " ")]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }