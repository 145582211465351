var render = function render(){
  var _vm$giftcard$images$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('app-primary', {
    attrs: {
      "footerTopBannerNone": ""
    }
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "product-intro"
  }, [_c('v-row', {
    staticClass: "ma-n20px"
  }, [_c('v-col', {
    staticClass: "pa-20px",
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('div', {
    staticClass: "product-intro__description"
  }, [_c('v-card', {
    attrs: {
      "variant": "flat",
      "image": "/images/sub/purchase/product-intro-bg.jpg"
    }
  }, [_c('v-responsive', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "830",
      "aspect-ratio": 830 / 428,
      "content-class": "d-flex justify-center align-center"
    }
  }, [_c('v-card', {
    staticClass: "pa-20px pa-md-24px",
    attrs: {
      "min-width": "348",
      "color": "white",
      "rounded": "0"
    }
  }, [_vm.giftcard.isBadge ? _c('v-chip', {
    staticClass: "mt-n4",
    attrs: {
      "color": "primary",
      "prepend-icon": "mdi-sale",
      "text": `최대 ${_vm.giftcard.discount}% 할인`
    }
  }) : _vm._e(), _c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "eager": "",
      "max-width": "240",
      "aspect-ratio": 1 / 1,
      "src": (_vm$giftcard$images$ = _vm.giftcard.images[0]) === null || _vm$giftcard$images$ === void 0 ? void 0 : _vm$giftcard$images$.src
    }
  }), _c('v-row', {
    staticClass: "v-row--xs pt-16px pt-md-24px",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tit-default', {
    staticClass: "text-center mw-300px"
  }, [_vm._v(_vm._s(_vm.giftcard.name))])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "pt-16px"
  }, [_c('div', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScrollFixed,
      expression: "onScrollFixed"
    }],
    staticClass: "scroll-fixed-wrap"
  }, [_c('div', {
    staticClass: "scroll-fixed"
  }, [_c('v-tabs', {
    model: {
      value: _vm.description,
      callback: function ($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }, [_c('v-tab', [_vm._v("상세설명")]), _c('v-tab', [_vm._v("유의사항")])], 1)], 1)]), _c('v-window', {
    model: {
      value: _vm.description,
      callback: function ($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }, [_c('v-window-item', [_c('div', {
    staticClass: "pt-30px pt-md-40px",
    attrs: {
      "id": "giftcard-content"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.giftcard.content)
    }
  })])]), _c('v-window-item', [_c('div', {
    staticClass: "pt-30px pt-md-40px",
    attrs: {
      "id": "giftcard-caution"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--xs mb-16px mb-md-24px"
  }, [_vm._v("유의사항")]), _c('u-txt-default', [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.giftcard.warning)
    }
  })])], 1)])], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "pa-20px",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('div', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScrollFixed,
      expression: "onScrollFixed"
    }],
    staticClass: "product-intro__info"
  }, [_vm.$vuetify.breakpoint.mdAndUp ? [_c('page-section-primary', {
    staticClass: "pt-0px pb-24px pb-lg-32px",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('div', {
          staticClass: "mb-16px mb-md-20px"
        }, [_c('div', {
          staticClass: "font-size-16 d-flex align-center"
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-gift-outline")]), _c('span', {
          staticClass: "font-weight-bold primary--text"
        }, [_vm._v("권종 및 수량")])], 1)]), _c('v-row', {
          staticClass: "v-row--xxs"
        }, _vm._l(_vm.order.pickups, function (pickup) {
          return _c('v-col', {
            key: pickup._id,
            attrs: {
              "cols": "12"
            }
          }, [_c('v-card', {
            attrs: {
              "variant": "flat",
              "color": "grey lighten-5"
            }
          }, [_c('div', {
            staticClass: "pa-12px pa-md-16px pa-lg-20px"
          }, [_c('v-row', {
            staticClass: "v-row--xs",
            attrs: {
              "align": "center",
              "justify": "space-between"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "12",
              "lg": ""
            }
          }, [_c('div', {
            staticClass: "font-size-16 font-weight-medium"
          }, [_vm._v(_vm._s(pickup.name))]), _c('div', {
            staticClass: "font-size-14 mt-4px mt-md-8px"
          }, [pickup.discount ? [_c('span', {
            staticClass: "primary--text"
          }, [_vm._v(_vm._s(pickup.discount) + "% 할인")]), _c('span', {
            staticClass: "ml-8px grey--text text--lighten-1 text-decoration-line-through"
          }, [_vm._v(_vm._s(pickup.faceamt.format()) + "원")])] : _vm._e(), _c('span', {
            staticClass: "ml-8px"
          }, [_vm._v(_vm._s(pickup.salePrice.format()) + "원")])], 2)]), _c('v-col', {
            attrs: {
              "cols": "12",
              "lg": "auto"
            }
          }, [_c('div', {
            staticClass: "number-field"
          }, [_c('pickup-quantity', {
            attrs: {
              "pickup": pickup
            },
            model: {
              value: pickup.quantity,
              callback: function ($$v) {
                _vm.$set(pickup, "quantity", $$v);
              },
              expression: "pickup.quantity"
            }
          })], 1)])], 1)], 1)])], 1);
        }), 1)];
      },
      proxy: true
    }], null, false, 3753685382)
  }), _c('v-divider'), _c('page-section-primary', {
    staticClass: "py-24px py-lg-32px",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('div', {
          staticClass: "mb-16px mb-md-20px"
        }, [_c('div', {
          staticClass: "font-size-16 d-flex align-center"
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-cash-multiple")]), _c('span', {
          staticClass: "font-weight-bold primary--text"
        }, [_vm._v("금액 및 권종 확인")])], 1)]), _c('v-card', {
          attrs: {
            "variant": "flat",
            "color": "grey lighten-5"
          }
        }, [_c('div', {
          staticClass: "pa-12px pa-md-16px pa-lg-20px"
        }, _vm._l(_vm.order.pickups.filter(function (pickup) {
          return 0 < pickup.quantity;
        }), function (pickup, index) {
          return _c('div', {
            key: pickup._id
          }, [index ? _c('v-divider', {
            staticClass: "my-8px my-md-12px"
          }) : _vm._e(), _c('div', [_c('v-row', {
            staticClass: "v-row--xs",
            attrs: {
              "align": "center",
              "justify": "space-between"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": ""
            }
          }, [_c('span', {
            staticClass: "font-size-14 text-grey-darken-4"
          }, [_vm._v(_vm._s(pickup.name))]), _c('span', {
            staticClass: "font-size-14 text-grey-lighten-1 ml-8px"
          }, [_vm._v("X" + _vm._s(pickup.quantity))])]), _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('span', {
            staticClass: "font-size-14 text-grey"
          }, [_vm._v(_vm._s(pickup.saleAmount.format()) + "원")])])], 1)], 1)], 1);
        }), 0)]), _c('v-card', {
          staticClass: "mt-8px",
          attrs: {
            "outlined": ""
          }
        }, [_c('div', {
          staticClass: "pa-12px pa-md-16px pa-lg-20px"
        }, [_c('div', [_c('v-row', {
          staticClass: "v-row--xs",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": ""
          }
        }, [_c('span', {
          staticClass: "font-size-14 font-weight-medium text-grey-darken-4"
        }, [_vm._v("결제 수단")])]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm.order.paymentMethod == `creditcard` ? _c('span', {
          staticClass: "font-size-14 text-grey"
        }, [_vm._v("카드결제")]) : _vm._e(), _vm.order.paymentMethod == `mobile` ? _c('span', {
          staticClass: "font-size-14 text-grey"
        }, [_vm._v("모바일결제")]) : _vm._e()])], 1)], 1), _vm.order.commissionAmount ? [_c('v-divider', {
          staticClass: "my-8px my-md-12px"
        }), _c('div', [_c('v-row', {
          staticClass: "v-row--xs",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": ""
          }
        }, [_c('span', {
          staticClass: "font-size-14 font-weight-medium text-grey-darken-4"
        }, [_vm._v("결제 수수료")])]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "font-size-14 text-grey"
        }, [_vm._v(_vm._s(_vm.order.commissionAmount.format()) + "원")])])], 1)], 1)] : _vm._e(), _c('v-divider', {
          staticClass: "my-8px my-md-12px"
        }), _c('div', [_c('v-row', {
          staticClass: "v-row--xs",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": ""
          }
        }, [_c('span', {
          staticClass: "font-size-14 font-weight-medium text-grey-darken-4"
        }, [_vm._v("상품 합계 금액")])]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "font-size-14 text-grey"
        }, [_vm._v(_vm._s(_vm.order.saleAmount.format()) + "원")])])], 1)], 1)], 2)])];
      },
      proxy: true
    }], null, false, 308867977)
  }), _c('v-divider'), _c('page-section-primary', {
    staticClass: "pt-24px pt-lg-32px pb-0",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('v-row', {
          staticClass: "v-row--xs",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "font-size-14 text-grey"
        }, [_vm._v("최종 결제 금액")])]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('b', {
          staticClass: "font-size-20 font-size-md-24"
        }, [_vm._v(_vm._s(_vm.order.totalAmount.format()))]), _c('span', {
          staticClass: "font-size-14 pl-2px"
        }, [_vm._v("원")])])])], 1)];
      },
      proxy: true
    }], null, false, 2805176120)
  }), _c('div', {
    staticClass: "pt-24px pt-lg-32px"
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.pay();
      }
    }
  }, [_vm._v(" 상품권 구매하기 ")])], 1)] : _vm._e()], 2)])], 1)], 1)]), _vm.$vuetify.breakpoint.lgAndDown ? [_c('v-bottom-navigation', {
    staticClass: "product-bottom-fixed",
    attrs: {
      "fixed": "",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "w-100 pa-12px"
  }, [_c('v-btn', {
    staticClass: "product-bottom-fixed__btn",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.bottomNav = !_vm.bottomNav;
      }
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("상품권 구매하기")])])], 1)]), _c('v-bottom-navigation', {
    staticClass: "product-bottom-fixed-view d-block",
    attrs: {
      "input-value": _vm.bottomNav,
      "fixed": "",
      "height": "auto"
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-0",
    attrs: {
      "block": "",
      "color": "transparent"
    },
    on: {
      "click": function ($event) {
        _vm.bottomNav = !_vm.bottomNav;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "24",
      "color": "grey"
    }
  }, [_vm._v("mdi-chevron-down")])], 1), _c('div', {
    staticClass: "w-100 pa-12px"
  }, [_c('div', {
    staticClass: "overflow-x-hidden overflow-y-auto",
    staticStyle: {
      "max-height": "50vh"
    }
  }, [_c('page-section-primary', {
    staticClass: "pt-0px pb-24px pb-lg-32px",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('div', {
          staticClass: "mb-16px mb-md-20px"
        }, [_c('div', {
          staticClass: "font-size-16 d-flex align-center"
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-gift-outline")]), _c('span', {
          staticClass: "font-weight-bold primary--text"
        }, [_vm._v("권종 및 수량")])], 1)]), _c('v-row', {
          staticClass: "v-row--xxs"
        }, _vm._l(_vm.order.pickups, function (pickup) {
          return _c('v-col', {
            key: pickup._id,
            attrs: {
              "cols": "12"
            }
          }, [_c('v-card', {
            attrs: {
              "variant": "flat",
              "color": "grey lighten-5"
            }
          }, [_c('div', {
            staticClass: "pa-12px pa-md-16px pa-lg-20px"
          }, [_c('v-row', {
            staticClass: "v-row--xs",
            attrs: {
              "align": "center",
              "justify": "space-between"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "12",
              "lg": ""
            }
          }, [_c('div', {
            staticClass: "font-size-16 font-weight-medium"
          }, [_vm._v(_vm._s(pickup.name))]), _c('div', {
            staticClass: "font-size-14 mt-4px mt-md-8px"
          }, [pickup.discount ? [_c('span', {
            staticClass: "primary--text"
          }, [_vm._v(_vm._s(pickup.discount) + "% 할인")]), _c('span', {
            staticClass: "ml-8px grey--text text--lighten-1 text-decoration-line-through"
          }, [_vm._v(_vm._s(pickup.faceamt.format()) + "원")])] : _vm._e(), _c('span', {
            staticClass: "ml-8px"
          }, [_vm._v(_vm._s(pickup.salePrice.format()) + "원")])], 2)]), _c('v-col', {
            attrs: {
              "cols": "12",
              "lg": "auto"
            }
          }, [_c('div', {
            staticClass: "number-field"
          }, [_c('pickup-quantity', {
            attrs: {
              "pickup": pickup
            },
            model: {
              value: pickup.quantity,
              callback: function ($$v) {
                _vm.$set(pickup, "quantity", $$v);
              },
              expression: "pickup.quantity"
            }
          })], 1)])], 1)], 1)])], 1);
        }), 1)];
      },
      proxy: true
    }], null, false, 3753685382)
  }), _c('v-divider'), _c('page-section-primary', {
    staticClass: "py-24px py-lg-32px",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('div', {
          staticClass: "mb-16px mb-md-20px"
        }, [_c('div', {
          staticClass: "font-size-16 d-flex align-center"
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-cash-multiple")]), _c('span', {
          staticClass: "font-weight-bold primary--text"
        }, [_vm._v("금액 및 권종 확인")])], 1)]), _c('v-card', {
          attrs: {
            "variant": "flat",
            "color": "grey lighten-5"
          }
        }, [_c('div', {
          staticClass: "pa-12px pa-md-16px pa-lg-20px"
        }, _vm._l(_vm.order.pickups.filter(function (pickup) {
          return 0 < pickup.quantity;
        }), function (pickup, index) {
          return _c('div', {
            key: pickup._id
          }, [index ? _c('v-divider', {
            staticClass: "my-8px my-md-12px"
          }) : _vm._e(), _c('div', [_c('v-row', {
            staticClass: "v-row--xs",
            attrs: {
              "align": "center",
              "justify": "space-between"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": ""
            }
          }, [_c('span', {
            staticClass: "font-size-14 text-grey-darken-4"
          }, [_vm._v(_vm._s(pickup.name))]), _c('span', {
            staticClass: "font-size-14 text-grey-lighten-1 ml-8px"
          }, [_vm._v("X" + _vm._s(pickup.quantity))])]), _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('span', {
            staticClass: "font-size-14 text-grey"
          }, [_vm._v(_vm._s(pickup.saleAmount.format()) + "원")])])], 1)], 1)], 1);
        }), 0)]), _c('v-card', {
          staticClass: "mt-8px",
          attrs: {
            "outlined": ""
          }
        }, [_c('div', {
          staticClass: "pa-12px pa-md-16px pa-lg-20px"
        }, [_c('div', [_c('v-row', {
          staticClass: "v-row--xs",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": ""
          }
        }, [_c('span', {
          staticClass: "font-size-14 font-weight-medium text-grey-darken-4"
        }, [_vm._v("결제 수단")])]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm.order.paymentMethod == `creditcard` ? _c('span', {
          staticClass: "font-size-14 text-grey"
        }, [_vm._v("카드결제")]) : _vm._e(), _vm.order.paymentMethod == `mobile` ? _c('span', {
          staticClass: "font-size-14 text-grey"
        }, [_vm._v("모바일결제")]) : _vm._e()])], 1)], 1), _vm.order.commissionAmount ? [_c('v-divider', {
          staticClass: "my-8px my-md-12px"
        }), _c('div', [_c('v-row', {
          staticClass: "v-row--xs",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": ""
          }
        }, [_c('span', {
          staticClass: "font-size-14 font-weight-medium text-grey-darken-4"
        }, [_vm._v("결제 수수료")])]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "font-size-14 text-grey"
        }, [_vm._v(_vm._s(_vm.order.commissionAmount.format()) + "원")])])], 1)], 1)] : _vm._e(), _c('v-divider', {
          staticClass: "my-8px my-md-12px"
        }), _c('div', [_c('v-row', {
          staticClass: "v-row--xs",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": ""
          }
        }, [_c('span', {
          staticClass: "font-size-14 font-weight-medium text-grey-darken-4"
        }, [_vm._v("상품 합계 금액")])]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "font-size-14 text-grey"
        }, [_vm._v(_vm._s(_vm.order.saleAmount.format()) + "원")])])], 1)], 1)], 2)])];
      },
      proxy: true
    }], null, false, 308867977)
  })], 1), _c('v-divider'), _c('page-section-primary', {
    staticClass: "pt-24px pt-lg-32px pb-0",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('v-row', {
          staticClass: "v-row--xs",
          attrs: {
            "align": "center",
            "justify": "space-between"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "font-size-14 text-grey"
        }, [_vm._v(" 최종 결제 금액 ")])]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "d-flex align-center text-primary"
        }, [_c('b', {
          staticClass: "font-size-20 font-size-md-24"
        }, [_vm._v(_vm._s(_vm.order.totalAmount.format()))]), _c('span', {
          staticClass: "font-size-14 pl-2px"
        }, [_vm._v("원")])])])], 1)];
      },
      proxy: true
    }], null, false, 2408810180)
  }), _c('div', {
    staticClass: "pt-24px pt-lg-32px"
  }, [_c('v-btn', {
    staticClass: "product-bottom-fixed__btn",
    attrs: {
      "size": "x-large",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.pay();
      }
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("상품권 구매하기")])])], 1)], 1)], 1)] : _vm._e(), _c('payment-gateway', {
    ref: "paymentGateway",
    on: {
      "complete": _vm.complete
    }
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }