<template>
    <!-- <div class="gift-card-category break-auto">
        <v-row :class="$vuetify.breakpoint.mdAndDown ? 'ma-n4px' : ''">
            <v-col cols="4" md="3" lg="2" :class="$vuetify.breakpoint.mdAndDown ? 'pa-4px' : ''">
                <v-btn stacked class="v-btn--size-xx-large w-100" @click="$emit(`update:model-value`, undefined)">
                    <template v-slot:prepend>
                        <v-img :aspect-ratio="1 / 1" width="40" src="/images/icon/icon-product.png" />
                    </template>
                    <span class="font-size-14 text-grey mt-4px mt-md-8px">전체상품</span>
                </v-btn>
            </v-col>
            <v-col v-for="category in categories" :key="category._id" cols="4" md="3" lg="2" :class="$vuetify.breakpoint.mdAndDown ? 'pa-4px' : ''">
                <v-btn stacked class="v-btn--size-xx-large w-100" @click="$emit(`input`, category._id)">
                    <template v-slot:prepend>
                        <v-img :aspect-ratio="1 / 1" width="40" :src="category.thumb.src" />
                    </template>
                    <span class="font-size-14 text-grey mt-4px mt-md-8px">{{ category.name }}</span>
                </v-btn>
            </v-col>
        </v-row>
    </div> -->

    <v-tabs>
        <v-tab @click="$emit('input', null)">전체상품</v-tab>
        <v-tab v-for="category in categories" :key="category._id" @click="$emit(`input`, category._id)">
            {{ category.name }}
        </v-tab>
    </v-tabs>
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: String,
    },
    components: {},
    data() {
        return {
            categories: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { categories } = await api.v1.gshop2.categories.gets();
            this.categories = categories;
        },
    },
};
</script>

<style lang="scss" scoped>
.gift-card-category {
    padding: calc(var(--grid-gutter) * 2) 0;
    margin-bottom: var(--tit-wrap-padding-bottom-lg);
}

.v-btn {
    --btn-hover-opacity: 0 !important;
    &:hover,
    &--active {
        box-shadow: var(--btn-box-shadow) rgba(var(--shadow-color), var(--shadow-opacity));
    }
    :deep() {
        .v-btn__overlay {
            opacity: 0 !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .v-btn {
        --btn-border-radius-xxl: 16px;
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 768px) {
    .v-btn {
        --btn-stacked-height-xxl: 76px;
    }
}
@media (max-width: 768px) {
    .v-btn {
        --btn-stacked-height-xxl: 96px;
    }
}
</style>
