<template>
    <u-text-field-outlined-rounded v-bind="$attrs" @input="value => $emit('input', value)">
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
    </u-text-field-outlined-rounded>
</template>

<script>
import UTextFieldOutlinedRounded from "@/components/publish/styles/forms/u-text-field-outlined-rounded.vue";

export default {
    props: {
    },
    components: {
        UTextFieldOutlinedRounded,
    },
    data() {
        return {
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
