<template>
    <v-row v-if="pickup" class="ma-n2px">
        <!-- <v-btn variant="outlined" class="rounded-sm" @click="decrease(-10)"><span class="text-primary">-1</span></v-btn> -->
        <v-col cols="auto" class="pa-2px">
            <div class="number-field">
                <v-btn icon class="number-field__minus rounded-sm" @click="decrease(-1)"><v-icon>mdi-minus</v-icon></v-btn>
                <v-text-field v-model="quantity" compact solo flat dense type="number" hide-details class="number-field__input" @input="update()" />
                <v-btn icon class="number-field__plus rounded-sm" @click="increase(1)"><v-icon>mdi-plus</v-icon></v-btn>
            </div>
        </v-col>
        <v-col cols="auto" class="pa-2px">
            <div class="number-field">
                <v-btn icon class="rounded-sm" @click="increase(5)">
                    <span class="primary--text text--lighten-3">+5</span>
                </v-btn>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        value: { type: Number, default: 0 },
        pickup: { type: Object, default: null },
    },
    data() {
        return {
            quantity: this.value,
        };
    },
    methods: {
        increase(quantity) {
            this.quantity = (+this.quantity) + quantity;
            this.update();
            this.$emit("move");
        },
        decrease(quantity) {
            this.quantity = (+this.quantity) + quantity;
            this.update();
            this.$emit("move");
        },
        update() {
            if (20 < this.quantity) this.quantity = 20;
            if (this.quantity < 0) this.quantity = 0;
            this.$emit("input", this.quantity);
        },
    },
};
</script>

<style lang="scss" scoped>
.number-field {
    display: flex;
    background: #fff;
    border: 1px solid var(--border-color);
    border-radius: 8px;

    .v-btn {
        min-width: initial;
    }
    &__input {
        width: 76px;
        text-align: center;
        --input-height-comfortable: var(--btn-height);

        ::v-deep(input) {
            text-align: center;
        }
        ::v-deep(.v-input__control) {
            min-height: var(--btn-height) !important;
        }
    }
}
@media (min-width: 768px) {
}
</style>