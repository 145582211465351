<template>
    <v-text-field v-bind="{ ...attrs_input_common, ...$attrs }" @input="value => $emit('input', value)">
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
    </v-text-field>
</template>

<script>
import { attrs_input_common } from "@/assets/variables";

export default {
    props: {},
    components: {},
    data() {
        return {
            attrs_input_common,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>