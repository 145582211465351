<template>
    <u-btn-flat-rounded :color="color">
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
    </u-btn-flat-rounded>
</template>

<script>
import UBtnFlatRounded from "@/components/publish/styles/buttons/u-btn-flat-rounded.vue";

export default {
    props: {
        color: { type: String, default: "primary" },
    },
    components: {
        UBtnFlatRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-btn.bg-white {
}
</style>
