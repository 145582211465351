var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper-container', _vm._b({
    staticClass: "gift-card-slide"
  }, 'swiper-container', _vm.swiperOptions, false), _vm._l(_vm.giftcards, function (giftcard) {
    return _c('swiper-slide', {
      key: giftcard._id
    }, [giftcard.isBadge ? _c('v-chip', {
      staticClass: "position-absolute",
      attrs: {
        "color": "primary",
        "prepend-icon": "mdi-sale",
        "text": giftcard.discount ? `최대 ${giftcard.discount}% 할인` : `할인`
      }
    }) : _vm._e(), _c('v-card', {
      attrs: {
        "variant": "outlined",
        "rounded": "circle",
        "to": `/sub/purchase/detail/${giftcard._id}`
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-img', {
      attrs: {
        "src": giftcard.thumb.src
      }
    })], 1)], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }