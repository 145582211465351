<template>
    <div class="gift-card-list break-auto">
        <template v-if="giftcards.length > 0">
            <v-row :class="$vuetify.breakpoint.mdAndDown ? 'ma-n4px ma-md-n6px' : ''">
                <v-col v-for="giftcard in giftcards" :key="giftcard._id" cols="4" sm="3" md="2" :class="$vuetify.breakpoint.mdAndDown ? 'pa-4px pa-md-6px' : ''">
                    <v-card class="gift-card-list__item" :to="`/gshop/giftcards/${giftcard._id}`">
                        <v-card :img="giftcard.thumb?.src" variant="outlined" rounded class="thumb-image">
                            <v-responsive :aspect-ratio="1 / 1" />
                        </v-card>
                        <v-card-subtitle class="text-center pb-2">
                            <txt class="txt--dark font-weight-bold">
                                {{ giftcard.name }}
                            </txt>
                        </v-card-subtitle>
                        <v-card-title class="py-0 justify-center primary--text">
                            <tit class="tit--xxs">{{ giftcard.salePrice.toLocaleString() }}원</tit>
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <div class="text-center">
                <v-icon color="grey lighten-2" class="font-size-120">mdi-alert-circle-outline</v-icon>
                <tit class="grey--text text--lighten-2 mt-5">상품 준비 중입니다</tit>
            </div>
        </template>
    </div>
</template>

<script>
import api from "@/api";
import Txt from "../typography/txt.vue";
import Tit from "../typography/tit.vue";
export default {
    props: {
        filter: { type: Object, default: {} },
    },
    components: { Txt, Tit },
    data() {
        return {
            giftcards: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            let { giftcards } = await api.v1.gshop2.giftcards.gets({ params: { ...this.$props.filter, type: "giftcard" } });
            this.giftcards = giftcards;
        },
    },
    watch: {
        filter: {
            deep: true,
            handler() {
                this?.search?.();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.gift-card-list {
    &__item {
        .thumb-image {
            transition: all 0.3s;
        }
        &:hover,
        &--active {
            .thumb-image {
                box-shadow: var(--shadow-elevation-10) rgba(var(--shadow-color), var(--shadow-opacity));
            }
        }
    }
}
.v-card {
    &::before {
        display: none;
    }
    :deep() {
        .v-card__overlay {
            opacity: 0 !important;
        }
    }
}
</style>
