var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gift-card-list break-auto"
  }, [_c('v-row', {
    class: _vm.$vuetify.display.mdAndDown ? 'ma-n4px ma-md-n6px' : ''
  }, _vm._l(_vm.brands, function (brand) {
    return _c('v-col', {
      key: brand._id,
      class: _vm.$vuetify.display.mdAndDown ? 'pa-4px pa-md-6px' : '',
      attrs: {
        "cols": "4",
        "sm": "3",
        "md": "2"
      }
    }, [_c('v-card', {
      attrs: {
        "variant": "outlined",
        "rounded": "circle"
      },
      on: {
        "click": function ($event) {
          return _vm.navigate(brand);
        }
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-img', {
      attrs: {
        "src": brand.thumb.src
      }
    })], 1)], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }