var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapAlign": "start"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("상품권 구매하기")];
      },
      proxy: true
    }])
  }, [_c('gift-card-recommend')], 1), _c('section', [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('gift-card-category', {
    model: {
      value: _vm.filter._category,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "_category", $$v);
      },
      expression: "filter._category"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('gift-card-search', {
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1)], 1)], 1), _c('v-divider', {
    staticClass: "gift-card-divider"
  })], 1), _c('page-section-primary', {
    staticClass: "pt-60px page-section--last",
    attrs: {
      "titWrapAlign": "start"
    }
  }, [_c('gift-card-List', {
    attrs: {
      "filter": _vm.filter
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }