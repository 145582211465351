<template>
    <app-primary v-if="ready" footerTopBannerNone>
        <!-- <div class="aside-contents"> -->
        <!-- S: Aside -->
        <!-- <div class="aside">
                <v-card rounded class="aside-card">
                    <v-card-text>
                        <v-row class="v-row--xs">
                            <v-col v-for="giftcard in brand.giftcards" :key="giftcard._id" cols="12">
                                <v-btn block color="white" class="category-btn" :to="`/sub/purchase/detail/${giftcard._id}`">
                                    <v-avatar color="white">
                                        <v-img :src="giftcard.thumb?.src" />
                                    </v-avatar>
                                    <span>{{ giftcard.name }}</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div> -->
        <!-- E: Aside -->
        <!-- </div> -->

        <!-- S: Contents -->
        <!-- <page-section-primary titWrapSize="tit-wrap" style="max-width: 1330px" class="py-lg-0px"> -->
        <page-section-primary class="page-section--first page-section--last">
            <!-- <template #subtit>Order</template>
                <template #tit>주문하기</template> -->
            <div class="product-intro">
                <v-row class="ma-n20px">
                    <v-col cols="12" lg="" class="pa-20px">
                        <div class="product-intro__description">
                            <!-- S: 상품 이미지 -->
                            <v-card variant="flat" image="/images/sub/purchase/product-intro-bg.jpg">
                                <v-responsive max-width="830" :aspect-ratio="830 / 428" content-class="d-flex justify-center align-center" class="mx-auto">
                                    <v-card min-width="348" color="white" rounded="0" class="pa-20px pa-md-24px">
                                        <v-chip v-if="giftcard.isBadge" color="primary" prepend-icon="mdi-sale" :text="`최대 ${giftcard.discount}% 할인`" class="mt-n4" />
                                        <!-- 상품 이미지 -->
                                        <v-img eager max-width="240" :aspect-ratio="1 / 1" :src="giftcard.images[0]?.src" class="mx-auto" />
                                        <v-row justify="center" class="v-row--xs pt-16px pt-md-24px">
                                            <!-- <v-col cols="12" md="auto" class="d-flex justify-center">
                                                        <v-avatar size="40">
                                                            <v-img src="/images/icon/icon-pizza.png" />
                                                        </v-avatar>
                                                    </v-col> -->
                                            <v-col cols="auto">
                                                <!-- 상품명 -->
                                                <u-tit-default class="text-center mw-300px">{{ giftcard.name }}</u-tit-default>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-responsive>
                            </v-card>
                            <!-- E: 상품 이미지 -->

                            <!-- S: 상세설명, 유의사항 -->
                            <div class="pt-16px">
                                <div v-scroll="onScrollFixed" class="scroll-fixed-wrap">
                                    <div class="scroll-fixed">
                                        <!-- <tabs-primary v-scroll-spy-active="{ selector: '.scroll-spy-item', class: 'v-tab--selected' }">
                                                    <tab-primary to="#giftcard-content" class="scroll-spy-item">상세설명</tab-primary>
                                                    <tab-primary to="#giftcard-caution" class="scroll-spy-item">유의사항</tab-primary>
                                                </tabs-primary> -->
                                        <v-tabs v-model="description">
                                            <v-tab>상세설명</v-tab>
                                            <v-tab>유의사항</v-tab>
                                        </v-tabs>
                                    </div>
                                </div>

                                <v-window v-model="description">
                                    <v-window-item>
                                        <!-- 상세설명 -->
                                        <div id="giftcard-content" class="pt-30px pt-md-40px">
                                            <p v-html="giftcard.content"></p>
                                        </div>
                                    </v-window-item>
                                    <v-window-item>
                                        <!-- 유의사항 -->
                                        <div id="giftcard-caution" class="pt-30px pt-md-40px">
                                            <u-tit-default class="tit--xs mb-16px mb-md-24px">유의사항</u-tit-default>
                                            <u-txt-default>
                                                <p v-html="giftcard.warning"></p>
                                            </u-txt-default>
                                        </div>
                                    </v-window-item>
                                </v-window>

                                <!-- <div v-scroll-spy>
                                            <div id="giftcard-content" class="pt-30px pt-md-40px">
                                                <p v-html="giftcard.content"></p>
                                            </div>

                                            <div id="giftcard-caution" class="pt-30px pt-md-40px">
                                                <u-tit-default class="tit--xs mb-16px mb-md-24px">유의사항</u-tit-default>
                                                <u-txt-default>
                                                    <p>
                                                        상품 특성상 구매 발권 이후에는 핀 번호 노출로 환불이 불가합니다. <br />
                                                        신중하게 구매를 결정해주세요.
                                                    </p>
                                                    <p>
                                                        상품 구매 시 본인 인증 후 결제가 됩니다. <br />
                                                        본인 인증이 완료된 번호로 발송되며 수신번호는 변경 할 수 없습니다.
                                                    </p>
                                                    <p>
                                                        소액 결제 시 1일 한도 20만원, 카드 결제 시 1일 한도 100만원(일시불) 이며 <br class="d-none d-md-block" />
                                                        구매 한도는 통신사 정책에 따라 상이할 수 있습니다.
                                                    </p>
                                                    <p>구매하신 쿠폰 코드는 마이페이지에서 확인 가능하며 1회에 한하여 재발송이 가능합니다.</p>
                                                    <p>구매하신 쿠폰은 카카오톡 또는 문자 발송 중 선택할 수 있습니다.</p>
                                                </u-txt-default>
                                            </div>
                                        </div> -->
                            </div>
                            <!-- E: 상세설명, 유의사항 -->
                        </div>
                    </v-col>
                    <v-col cols="12" lg="auto" class="pa-20px">
                        <!-- S: 상품선택 -->
                        <div v-scroll="onScrollFixed" class="product-intro__info">
                            <!-- PC, Mobile 적응형으로 작업되어있습니다(PC 상단 영역, Mobile 하단 고정영역) -->
                            <!-- S: PC 상단 영역 -->
                            <template v-if="$vuetify.breakpoint.mdAndUp">
                                <!-- S: 권종 및 수량 -->
                                <page-section-primary class="pt-0px pb-24px pb-lg-32px">
                                    <template #pageSectionFoot>
                                        <div class="mb-16px mb-md-20px">
                                            <div class="font-size-16 d-flex align-center">
                                                <v-icon color="primary" class="mr-2">mdi-gift-outline</v-icon>
                                                <span class="font-weight-bold primary--text">권종 및 수량</span>
                                            </div>
                                        </div>

                                        <v-row class="v-row--xxs">
                                            <v-col v-for="pickup in order.pickups" :key="pickup._id" cols="12">
                                                <v-card variant="flat" color="grey lighten-5">
                                                    <div class="pa-12px pa-md-16px pa-lg-20px">
                                                        <v-row align="center" justify="space-between" class="v-row--xs">
                                                            <v-col cols="12" lg="">
                                                                <div class="font-size-16 font-weight-medium">{{ pickup.name }}</div>
                                                                <div class="font-size-14 mt-4px mt-md-8px">
                                                                    <template v-if="pickup.discount">
                                                                        <span class="primary--text">{{ pickup.discount }}% 할인</span>
                                                                        <span class="ml-8px grey--text text--lighten-1 text-decoration-line-through">{{ pickup.faceamt.format() }}원</span>
                                                                    </template>
                                                                    <span class="ml-8px">{{ pickup.salePrice.format() }}원</span>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="12" lg="auto">
                                                                <div class="number-field">
                                                                    <pickup-quantity v-model="pickup.quantity" :pickup="pickup" />
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </page-section-primary>
                                <!-- E: 권종 및 수량 -->
                                <v-divider />
                                <!-- S: 결제수단 -->
                                <!-- <page-section-primary class="py-24px py-lg-32px">
                                    <template #pageSectionFoot>
                                        <div class="mb-16px mb-md-20px">
                                            <div class="font-size-16 font-weight-medium text-grey-darken-4"><span class="text-grey-lighten-1 pr-8px">02</span>결제수단</div>
                                        </div>

                                        <v-tabs v-model="order.paymentMethod" grow hide-slider show-arrows class="v-tabs--arrows-gradient card-tabs">
                                            <v-tab v-if="['both', 'creditcard'].includes(giftcard.paymentMethod)" value="creditcard">
                                                <div class="px-8px px-sm-12px px-lg-20px">
                                                    <div>
                                                        <v-row class="v-row--xxs font-size-14 text-primary">
                                                            <v-col cols="auto"> 수수료 {{ giftcard.commission.creditcard }}% </v-col>
                                                            <v-col cols="auto">
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                    <div>
                                                        <v-row align="center" justify="space-between" class="v-row--sm font-size-16">
                                                            <v-col cols="auto"> 카드 결제 </v-col>
                                                            <v-col cols="auto">
                                                                <v-icon size="20">mdi-check</v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </div>
                                            </v-tab>
                                            <v-tab v-if="['both', 'mobile'].includes(giftcard.paymentMethod)" value="mobile">
                                                <div class="px-8px px-sm-12px px-lg-20px">
                                                    <div>
                                                        <v-row class="v-row--xxs font-size-14 text-primary">
                                                            <v-col cols="auto"> 수수료 {{ giftcard.commission.mobile }}% </v-col>
                                                            <v-col cols="auto">
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                    <div>
                                                        <v-row align="center" justify="space-between" class="v-row--sm font-size-16">
                                                            <v-col cols="auto"> 휴대폰 결제 </v-col>
                                                            <v-col cols="auto">
                                                                <v-icon size="20">mdi-check</v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </div>
                                            </v-tab>
                                        </v-tabs>
                                    </template>
                                </page-section-primary> -->
                                <!-- E: 결제수단 -->
                                <!-- <v-divider /> -->
                                <!-- S: 금액 및 권종 확인 -->
                                <page-section-primary class="py-24px py-lg-32px">
                                    <template #pageSectionFoot>
                                        <div class="mb-16px mb-md-20px">
                                            <div class="font-size-16 d-flex align-center">
                                                <v-icon color="primary" class="mr-2">mdi-cash-multiple</v-icon>
                                                <span class="font-weight-bold primary--text">금액 및 권종 확인</span>
                                            </div>
                                        </div>

                                        <v-card variant="flat" color="grey lighten-5">
                                            <div class="pa-12px pa-md-16px pa-lg-20px">
                                                <div v-for="(pickup, index) in order.pickups.filter((pickup) => 0 < pickup.quantity)" :key="pickup._id">
                                                    <v-divider v-if="index" class="my-8px my-md-12px" />
                                                    <div>
                                                        <v-row align="center" justify="space-between" class="v-row--xs">
                                                            <v-col cols="">
                                                                <span class="font-size-14 text-grey-darken-4">{{ pickup.name }}</span>
                                                                <span class="font-size-14 text-grey-lighten-1 ml-8px">X{{ pickup.quantity }}</span>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <span class="font-size-14 text-grey">{{ pickup.saleAmount.format() }}원</span>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-card>

                                        <v-card outlined class="mt-8px">
                                            <div class="pa-12px pa-md-16px pa-lg-20px">
                                                <div>
                                                    <v-row align="center" justify="space-between" class="v-row--xs">
                                                        <v-col cols="">
                                                            <span class="font-size-14 font-weight-medium text-grey-darken-4">결제 수단</span>
                                                        </v-col>
                                                        <v-col cols="auto">
                                                            <span v-if="order.paymentMethod == `creditcard`" class="font-size-14 text-grey">카드결제</span>
                                                            <span v-if="order.paymentMethod == `mobile`" class="font-size-14 text-grey">모바일결제</span>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                                <template v-if="order.commissionAmount">
                                                    <v-divider class="my-8px my-md-12px" />
                                                    <div>
                                                        <v-row align="center" justify="space-between" class="v-row--xs">
                                                            <v-col cols="">
                                                                <span class="font-size-14 font-weight-medium text-grey-darken-4">결제 수수료</span>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <span class="font-size-14 text-grey">{{ order.commissionAmount.format() }}원</span>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </template>
                                                <v-divider class="my-8px my-md-12px" />
                                                <div>
                                                    <v-row align="center" justify="space-between" class="v-row--xs">
                                                        <v-col cols="">
                                                            <span class="font-size-14 font-weight-medium text-grey-darken-4">상품 합계 금액</span>
                                                        </v-col>
                                                        <v-col cols="auto">
                                                            <span class="font-size-14 text-grey">{{ order.saleAmount.format() }}원</span>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </div>
                                        </v-card>
                                    </template>
                                </page-section-primary>
                                <!-- E: 금액 및 권종 확인 -->

                                <v-divider />

                                <!-- S: 최종 결제 금액 -->
                                <page-section-primary class="pt-24px pt-lg-32px pb-0">
                                    <template #pageSectionFoot>
                                        <v-row align="center" justify="space-between" class="v-row--xs">
                                            <v-col cols="auto">
                                                <span class="font-size-14 text-grey">최종 결제 금액</span>
                                            </v-col>
                                            <v-col cols="auto">
                                                <div class="d-flex align-center">
                                                    <b class="font-size-20 font-size-md-24">{{ order.totalAmount.format() }}</b>
                                                    <span class="font-size-14 pl-2px">원</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </page-section-primary>
                                <!-- E: 최종 결제 금액 -->

                                <!-- 버튼 영역 -->
                                <div class="pt-24px pt-lg-32px">
                                    <v-btn block color="primary" class="v-size--xx-large" @click="pay()"> 상품권 구매하기 </v-btn>
                                </div>
                            </template>
                            <!-- E: PC 상단 영역 -->
                        </div>
                        <!-- E: 상품선택 -->
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>

        <!-- PC, Mobile 적응형으로 작업되어있습니다(PC 상단 영역, Mobile 하단 고정영역) -->
        <!-- S: Mobile 하단 고정영역 -->
        <template v-if="$vuetify.breakpoint.lgAndDown">
            <v-bottom-navigation fixed height="auto" class="product-bottom-fixed">
                <div class="w-100 pa-12px">
                    <v-btn color="primary" class="product-bottom-fixed__btn" @click="bottomNav = !bottomNav">
                        <span class="white--text">상품권 구매하기</span>
                    </v-btn>
                </div>
            </v-bottom-navigation>
            <v-bottom-navigation v-model="value" :input-value="bottomNav" fixed height="auto" class="product-bottom-fixed-view d-block">
                <v-btn block color="transparent" class="rounded-0" @click="bottomNav = !bottomNav">
                    <v-icon size="24" color="grey">mdi-chevron-down</v-icon>
                </v-btn>
                <div class="w-100 pa-12px">
                    <div class="overflow-x-hidden overflow-y-auto" style="max-height: 50vh">
                        <!-- S: 결제수단 -->
                        <!-- <page-section-primary class="py-24px py-lg-32px pt-0">
                            <template #pageSectionFoot>
                                <div class="mb-16px mb-md-20px" ref="paymentMethod">
                                    <div class="font-size-16 font-weight-medium text-grey-darken-4"><span class="text-grey-lighten-1 pr-8px">01</span>결제수단</div>
                                </div>

                                <v-tabs v-model="order.paymentMethod" grow hide-slider show-arrows class="v-tabs--arrows-gradient card-tabs">
                                    <v-tab v-if="['both', 'creditcard'].includes(giftcard.paymentMethod)" value="creditcard">
                                        <div class="px-8px px-sm-12px px-lg-20px">
                                            <div>
                                                <v-row class="v-row--xxs font-size-14 text-primary">
                                                    <v-col cols="auto"> 수수료 {{ giftcard.commission.creditcard }}% </v-col>
                                                    <v-col cols="auto">
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div>
                                                <v-row align="center" justify="space-between" class="v-row--sm font-size-16">
                                                    <v-col cols="auto"> 카드 결제 </v-col>
                                                    <v-col cols="auto">
                                                        <v-icon size="20">mdi-check</v-icon>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </v-tab>
                                    <v-tab v-if="['both', 'mobile'].includes(giftcard.paymentMethod)" value="mobile">
                                        <div class="px-8px px-sm-12px px-lg-20px">
                                            <div>
                                                <v-row class="v-row--xxs font-size-14 text-primary">
                                                    <v-col cols="auto"> 수수료 {{ giftcard.commission.mobile }}% </v-col>
                                                    <v-col cols="auto">
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <div>
                                                <v-row align="center" justify="space-between" class="v-row--sm font-size-16">
                                                    <v-col cols="auto"> 휴대폰 결제 </v-col>
                                                    <v-col cols="auto">
                                                        <v-icon size="20">mdi-check</v-icon>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </v-tab>
                                </v-tabs>
                            </template>
                        </page-section-primary> -->
                        <!-- E: 결제수단 -->
                        <!-- <v-divider /> -->
                        <!-- S: 권종 및 수량 -->
                        <page-section-primary class="pt-0px pb-24px pb-lg-32px">
                            <template #pageSectionFoot>
                                <div class="mb-16px mb-md-20px">
                                    <div class="font-size-16 d-flex align-center">
                                        <v-icon color="primary" class="mr-2">mdi-gift-outline</v-icon>
                                        <span class="font-weight-bold primary--text">권종 및 수량</span>
                                    </div>
                                </div>

                                <v-row class="v-row--xxs">
                                    <v-col v-for="pickup in order.pickups" :key="pickup._id" cols="12">
                                        <v-card variant="flat" color="grey lighten-5">
                                            <div class="pa-12px pa-md-16px pa-lg-20px">
                                                <v-row align="center" justify="space-between" class="v-row--xs">
                                                    <v-col cols="12" lg="">
                                                        <div class="font-size-16 font-weight-medium">{{ pickup.name }}</div>
                                                        <div class="font-size-14 mt-4px mt-md-8px">
                                                            <template v-if="pickup.discount">
                                                                <span class="primary--text">{{ pickup.discount }}% 할인</span>
                                                                <span class="ml-8px grey--text text--lighten-1 text-decoration-line-through">{{ pickup.faceamt.format() }}원</span>
                                                            </template>
                                                            <span class="ml-8px">{{ pickup.salePrice.format() }}원</span>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" lg="auto">
                                                        <div class="number-field">
                                                            <pickup-quantity v-model="pickup.quantity" :pickup="pickup" />
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </template>
                        </page-section-primary>
                        <!-- E: 권종 및 수량 -->
                        <v-divider />
                        <!-- S: 금액 및 권종 확인 -->
                        <page-section-primary class="py-24px py-lg-32px">
                            <template #pageSectionFoot>
                                <div class="mb-16px mb-md-20px">
                                    <div class="font-size-16 d-flex align-center">
                                        <v-icon color="primary" class="mr-2">mdi-cash-multiple</v-icon>
                                        <span class="font-weight-bold primary--text">금액 및 권종 확인</span>
                                    </div>
                                </div>

                                <v-card variant="flat" color="grey lighten-5">
                                    <div class="pa-12px pa-md-16px pa-lg-20px">
                                        <div v-for="(pickup, index) in order.pickups.filter((pickup) => 0 < pickup.quantity)" :key="pickup._id">
                                            <v-divider v-if="index" class="my-8px my-md-12px" />
                                            <div>
                                                <v-row align="center" justify="space-between" class="v-row--xs">
                                                    <v-col cols="">
                                                        <span class="font-size-14 text-grey-darken-4">{{ pickup.name }}</span>
                                                        <span class="font-size-14 text-grey-lighten-1 ml-8px">X{{ pickup.quantity }}</span>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <span class="font-size-14 text-grey">{{ pickup.saleAmount.format() }}원</span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </div>
                                </v-card>

                                <v-card outlined class="mt-8px">
                                    <div class="pa-12px pa-md-16px pa-lg-20px">
                                        <div>
                                            <v-row align="center" justify="space-between" class="v-row--xs">
                                                <v-col cols="">
                                                    <span class="font-size-14 font-weight-medium text-grey-darken-4">결제 수단</span>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <span v-if="order.paymentMethod == `creditcard`" class="font-size-14 text-grey">카드결제</span>
                                                    <span v-if="order.paymentMethod == `mobile`" class="font-size-14 text-grey">모바일결제</span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <template v-if="order.commissionAmount">
                                            <v-divider class="my-8px my-md-12px" />
                                            <div>
                                                <v-row align="center" justify="space-between" class="v-row--xs">
                                                    <v-col cols="">
                                                        <span class="font-size-14 font-weight-medium text-grey-darken-4">결제 수수료</span>
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <span class="font-size-14 text-grey">{{ order.commissionAmount.format() }}원</span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </template>
                                        <v-divider class="my-8px my-md-12px" />
                                        <div>
                                            <v-row align="center" justify="space-between" class="v-row--xs">
                                                <v-col cols="">
                                                    <span class="font-size-14 font-weight-medium text-grey-darken-4">상품 합계 금액</span>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <span class="font-size-14 text-grey">{{ order.saleAmount.format() }}원</span>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                </v-card>
                            </template>
                        </page-section-primary>
                        <!-- E: 금액 및 권종 확인 -->
                    </div>

                    <v-divider />

                    <!-- S: 최종 결제 금액 -->
                    <page-section-primary class="pt-24px pt-lg-32px pb-0">
                        <template #pageSectionFoot>
                            <v-row align="center" justify="space-between" class="v-row--xs">
                                <v-col cols="auto">
                                    <span class="font-size-14 text-grey"> 최종 결제 금액 </span>
                                </v-col>
                                <v-col cols="auto">
                                    <div class="d-flex align-center text-primary">
                                        <b class="font-size-20 font-size-md-24">{{ order.totalAmount.format() }}</b>
                                        <span class="font-size-14 pl-2px">원</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </template>
                    </page-section-primary>
                    <!-- E: 최종 결제 금액 -->

                    <!-- 버튼 영역 -->
                    <div class="pt-24px pt-lg-32px">
                        <v-btn size="x-large" color="primary" class="product-bottom-fixed__btn" @click="pay()">
                            <span class="white--text">상품권 구매하기</span>
                        </v-btn>
                    </div>
                </div>
            </v-bottom-navigation>
        </template>
        <!-- E: Mobile 하단 고정영역 -->
        <!-- E: Contents -->

        <payment-gateway ref="paymentGateway" @complete="complete" />
    </app-primary>
</template>

<script>
import api from "@/api";
import { attrs_input_common } from "@/assets/variables";

import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import PageSectionSecondary from "@/sets/parents/pages/page-section-secondary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

import IconArrowRounded from "@/components/publish/styles/icons/icon-arrow-rounded.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import PickupQuantity from "@/components/client/gshop/pickup-quantity.vue";
import PaymentGateway from "@/components/client/gshop/payment-gateway.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        PageSectionSecondary,
        BtnPrimary,

        IconArrowRounded,
        UTitDefault,
        UTxtDefault,

        PickupQuantity,
        PaymentGateway,
    },
    data() {
        return {
            ready: false,

            attrs_input_common,

            TabsPrimary: 0,

            value: 1,
            bottomNav: false,

            giftcard: null,

            order: {
                pickups: [],
                paymentMethod: null,

                saleAmount: 0,
                commissionAmount: 0,
                totalAmount: 0,
            },

            brand: {
                giftcards: [],
            },

            description: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.ready = false;

            let { user: me } = this.accessToken ? await api.v1.me.get() : {};

            let { giftcard } = await api.v1.gshop2.giftcards.get({ _id: this.$route.params._id });
            this.giftcard = giftcard;
            this.order = {
                pickups: giftcard.options.map((option, index) => ({
                    ...option,
                    quantity: 1 < giftcard.options.length ? 0 : 1,
                    get saleAmount() {
                        return this.salePrice * this.quantity;
                    },
                    get discount() {
                        return (1000 - parseInt((this.salePrice / this.faceamt) * 1000)) / 10;
                    },
                })),
                paymentMethod: "creditcard",

                buyer: me,
                receiver: me,

                get saleAmount() {
                    return this.pickups.reduce((saleAmount, pickup) => saleAmount + pickup.saleAmount, 0);
                },
                get commissionAmount() {
                    return (this.saleAmount * (giftcard.commission?.[this.paymentMethod] || 0)) / 100;
                },
                get totalAmount() {
                    return this.saleAmount + this.commissionAmount;
                },
            };

            this.brand = await api.v1.gshop2.giftcards.gets({ params: { _brand: giftcard._brand } });

            this.ready = true;
            this.$nextTick(this.onScrollFixed);
        },

        increase(pickup, quantity) {
            let max = 20;
            pickup.quantity = max < pickup.quantity + quantity ? max : pickup.quantity + quantity;
        },
        decrease(pickup, quantity) {
            let min = 0;
            pickup.quantity = pickup.quantity + quantity < min ? min : pickup.quantity + quantity;
        },

        validate() {
            if (!this.accessToken) {
                this.$router.push("/login");
                return;
            }

            try {
                if (!this.order.pickups.find((pickup) => pickup.quantity)) throw new Error("구매하실 상품권을 선택해주세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },

        async pay() {
            if (this.validate()) {
                let { order } = await api.v1.gshop2.orders.post({
                    ...this.order,
                    pickups: this.order.pickups.filter((pickup) => pickup.quantity),
                });

                this.$refs.paymentGateway.order = order;
                this.$nextTick(() => {
                    this.$refs.paymentGateway.pay();
                });
            }
        },

        complete(payload) {
            if (payload.resCode === "3001" || payload.resCode === "0000") {
                alert("결제가 완료되었습니다.");
                this.$router.go(0);
            } else {
                alert(`[${payload.resCode}] ${payload.resMessage}`);
            }
        },

        onScrollFixed() {
            let scrollPosition = 0,
                mobileWidth = 1200;

            let scrollFixedWrap = document.querySelector(".scroll-fixed-wrap"),
                scrollFixed = document.querySelector(".scroll-fixed"),
                absoluteTop = 0,
                headerHeight = 56,
                tabHeight = 46;
            if (window.innerWidth > mobileWidth) {
                headerHeight = 120;
                tabHeight = 50;
            }

            scrollPosition = window.scrollY || window.pageYOffset;
            if (window.innerWidth > mobileWidth) {
                // PC
                absoluteTop = window.pageYOffset + scrollFixedWrap.getBoundingClientRect().top - headerHeight - 1;
            } else {
                // Mobile
                absoluteTop = window.pageYOffset + scrollFixedWrap.getBoundingClientRect().top - headerHeight;
            }

            if (scrollPosition < absoluteTop) {
                scrollFixed.classList.remove("active");
            } else {
                scrollFixed.classList.add("active");
            }
        },

        handleIntersect(entries, observer) {
            if (entries[0].isIntersecting) {
                this.TabsPrimary = "tab-3";
            } else {
                this.TabsPrimary = "tab-1";
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    watch: {
        "$router.params"() {
            this.init();
        },
    },
};
</script>

<style lang="scss" scoped>
// aside
.aside-contents {
    --main-contents-gutter: var(--container-gutter);
    --aside-card-width: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100%;
    overflow: hidden;
    margin-top: calc(var(--contents-padding-top) * -1);
    margin-bottom: calc(var(--contents-padding-bottom) * -1);
    padding: var(--main-contents-gutter);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .aside-contents {
        --main-contents-gutter: 20px;
        --aside-card-width: 300px;

        padding: calc(var(--main-contents-gutter) - 10px) var(--main-contents-gutter) calc(var(--main-contents-gutter) - 10px) calc(var(--aside-card-width) + (var(--main-contents-gutter) * 2));
    }
}
@media (min-width: 1600px) {
    .aside-contents {
        --main-contents-gutter: 70px;
        --aside-card-width: 390px;
        padding: calc(var(--main-contents-gutter) - 10px) var(--main-contents-gutter) calc(var(--main-contents-gutter) - 10px) calc(var(--aside-card-width) + var(--main-contents-gutter) + (var(--main-contents-gutter) - 10px));
    }
}
.aside-card {
    height: 200px;
    padding: 12px;
    > .v-card-text {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
        border-radius: 0 !important;
        &::-webkit-scrollbar {
            width: 16px;
            border-left: 12px solid transparent;
            background-clip: padding-box;
        }
        &::-webkit-scrollbar-thumb {
            width: 4px;
            border-left: 12px solid transparent;
            background-clip: padding-box;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .aside-card {
        padding: 24px;
        margin-bottom: var(--tit-wrap-padding-bottom);
        > .v-card-text {
            &::-webkit-scrollbar {
                width: 30px;
                border-left: 24px solid transparent;
            }
            &::-webkit-scrollbar-thumb {
                width: 6px;
                border-left: 24px solid transparent;
            }
        }
    }
}
@media (min-width: 1200px) {
    .aside {
        position: absolute;
        // top: var(--main-contents-gutter);
        left: var(--main-contents-gutter);
        z-index: 1;
        width: var(--aside-card-width);
        height: calc(100% - (var(--main-contents-gutter) * 2));
    }
    .aside-card {
        height: 100%;
        margin-bottom: 0;
    }
}
.category-btn {
    height: 60px !important;
    font-size: 1.4rem !important;
    padding: 0 12px !important;
    font-weight: 500;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0);
    &:hover {
    }
    .v-btn__content {
        flex: auto;
        white-space: break-spaces;
    }

    .v-avatar {
        width: 40px;
        height: 40px;
        & + span {
            margin-left: 8px;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .category-btn {
        height: 80px !important;
        font-size: 1.6rem !important;
        padding: 0 16px !important;

        .v-avatar {
            width: 48px;
            height: 48px;
            & + span {
                margin-left: 12px;
            }
        }
    }
}
@media (min-width: 1200px) {
}

// scroll-fixed
.scroll-fixed-wrap {
    --tab-height: 50px;
    height: var(--tab-height);
    transition: 0.25s ease-out;
    .card-tabs {
        display: flex;
        height: var(--tab-height);
        .v-tab {
            width: 50% !important;
            max-width: none;
            height: 100% !important;
        }
    }
    .v-tab {
        &--active {
        }
    }
}
.scroll-fixed {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    &.active {
        position: fixed;
        top: calc(var(--header-height) - 1px);
        left: 0;
        width: 100%;
        z-index: 6;
    }
    &:not(.active) {
        .container {
            max-width: 1330px;
            padding: 0;
        }
    }

    ::-webkit-scrollbar {
        width: 12px; /* 스크롤바의 너비 */
    }
    ::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
        background: var(--v-theme-grey-lighten2); /* 스크롤바의 색상 */
        border-radius: 9999px;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
        background: var(--v-theme-grey-lighten4); /*스크롤바 뒷 배경 색상*/
        border-radius: 9999px;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .scroll-fixed-wrap {
        --tab-height: 50px;
    }
    .scroll-fixed {
        &.active {
            top: calc(var(--header-height) - 1px);
            width: 100%;
            min-height: var(--tab-height);
        }
    }
}
@media (min-width: 1920px) {
    .scroll-fixed {
        &.active {
            width: var(--description-width);
            left: calc(var(--aside-card-width) + var(--main-contents-gutter) + var(--main-contents-gutter) - 10px);
        }
    }
}
@media (max-width: 576px) {
    .scroll-fixed {
        .container {
            padding: 0;
        }
    }
}

// 상품상세
.product-intro {
    &__description {
        width: 100%;
    }
    &__info {
        width: 100%;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .product-intro {
        --info-width: 400px;

        &__info {
            max-width: var(--info-width);
        }
    }
}
@media (min-width: 1200px) {
    .product-intro {
        --info-width: 340px;
    }
}
@media (min-width: 1600px) {
    .product-intro {
        --description-width: 716px;
        --info-width: 460px;

        &__description {
            max-width: var(--description-width);
        }
    }
}
// 상품상세 모바일하단 고정영역
.product-bottom {
    &-fixed,
    &-fixed-view {
        // display: block !important;
        z-index: 6 !important;
    }
    &-fixed {
        box-shadow: none !important;

        &__btn {
            min-width: auto !important;
            width: 100%;
            height: var(--btn-height-xl) !important;
        }
        .v-btn--active > .v-btn__overlay {
            display: none;
        }
    }
    &-fixed-view {
        :deep() {
            .v-bottom-navigation__content {
                flex-direction: column;
                > .v-btn--size-default.v-btn--stacked {
                    width: 100%;
                    min-width: auto !important;
                    max-width: none !important;
                    height: var(--btn-height) !important;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .product-bottom {
        &-fixed,
        &-fixed-view {
            display: none !important;
        }
    }
}
@media (min-width: 1200px) {
}

.card-tabs {
    height: 70px;
    border: 0;
    margin: 0 -4px !important;
    .v-tab {
        display: block;
        height: 100% !important;
        margin: 0 4px !important;
        padding: 0 !important;
        border-radius: var(--card-border-radius);
        &::before,
        &::after {
            display: none;
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
        &--selected {
            &:not(.text-primary) {
            }
        }
        :deep() {
            .v-btn__content {
                display: block !important;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .card-tabs {
        height: 82px;
        .v-tab {
            margin: 0 6px !important;
        }
    }
}
@media (min-width: 1200px) {
}

.product-bottom-fixed {
    &__btn {
        max-width: 100% !important;
    }
}
</style>
